import {useTranslation} from 'react-i18next';
import {Avatar, Box, Flex, Menu, MenuButton, MenuItem, MenuList, Stack, Text,} from '@chakra-ui/react';

import Logo from '../assets/logo.png';
import NL_FLAG from '../assets/flags/nl.png';
import EN_FLAG from '../assets/flags/en.png';

const FLAGS = {NL_FLAG, EN_FLAG};

type TranslationLanguage = {
  name: string;
  flag: 'NL_FLAG' | 'EN_FLAG';
};

export default function Header({
                                 displayName,
                                 height,
                                 onClickSignOut,
                               }: {
  displayName: string;
  height: any;
  onClickSignOut: any;
}) {
  const [t, i18n] = useTranslation();
  const lang = i18n.language;

  const translationLang: TranslationLanguage = t('header.language', {
    returnObjects: true,
  });
  const translationSecondLang: TranslationLanguage = t(
    'header.secondLanguage',
    {returnObjects: true}
  );

  return (
    <Flex
      height={height}
      py={{base: 2}}
      px={{base: 4}}
      backgroundColor="white"
    >
      <Flex flex={{base: 1}} display={{base: 'flex'}}>
        <img
          src={Logo}
          alt="Hartwig Medical Foundation logo"
          width={125}
          height="80%"
          style={{alignSelf: 'center'}}
        />
      </Flex>

      <Box display="flex" flexDirection="row">
        <Menu>
          <MenuButton>
            <Stack direction={'row'}>
              <img
                src={FLAGS[translationLang.flag]}
                width={20}
                height={20}
                alt="Flag"
              />
              <Text>{t('header.language.name')}</Text>
            </Stack>
          </MenuButton>
          <MenuList>
            <MenuItem
              value={lang === 'en' ? 'Nederlands' : 'English'}
              onClick={() => i18n.changeLanguage(lang === 'en' ? 'nl' : 'en')}
            >
              <img
                src={FLAGS[translationSecondLang.flag]}
                width={20}
                height={20}
                alt=""
              />
              {t('header.secondLanguage.name')}
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton ml={4}>
            <Avatar name={displayName} alignSelf="center"/>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={onClickSignOut}>{t('header.signOut')}</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}
