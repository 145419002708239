import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {AccordionButton, AccordionItem, AccordionPanel, Box} from '@chakra-ui/react';
import {Form, Formik} from 'formik';
import React from 'react';
import {InputFormik, RadioGroupChakraFormik, SelectFormik} from '../../Formik';
import {Option} from '../../Formik/SelectFormik';
import {StepTumorSampleRegistrationProps} from '../interfaces/Steps';
import {observer} from 'mobx-react-lite';
import {StepRequestForm, useStepRequest} from '../hooks/useStepRequest';
import {useTranslation} from 'react-i18next';
import {isSingleAnalysisType} from '../../../stores/data';
import {PrefixedInputFormik} from "../../Formik/PrefixedInputFormik";

export const StepRequest: React.FC<StepTumorSampleRegistrationProps<StepRequestForm>> = observer(
  ({
     dataStore,
     onValidate,
     active,
     innerRef,
   }: StepTumorSampleRegistrationProps<StepRequestForm>) => {
    const [t] = useTranslation();
    const {
      initialValuesForm,
      isDisabled,
      onChangeHospital,
      onChangeAnalysisType,
      onSubmit,
      validationSchema,
    } = useStepRequest(dataStore);

    const computeReportLabelPreview = (values: StepRequestForm) => {
      const contractOption = dataStore.contractOptions.find((c) => c.contractCode === values.contractCode)
      const suffix = values.hospitalSampleLabel !== "" ? `-${values.hospitalSampleLabel}` : ""
      if (contractOption?.samplesAreDiagnostic) {
        return `${values.patientId}${suffix}`
      }
      return `${values.studyIdPrefix}${values.patientId}${suffix}`
    }

    const shouldDisplayReportLabelPreview = (values: StepRequestForm) => {
      return values.patientId !== "" && computeReportLabelPreview(values) !== ""
    }

    return (
      <>
        <AccordionItem pb={4}>
          {({isExpanded}) => (
            <>
              <h2>
                <AccordionButton>
                  <Box flex="1" marginLeft={!active ? '2' : '6'} textAlign="left">
                    {t('tumorRegistrationProcess.registrationSetup.title')}
                  </Box>
                  {isExpanded ? (<MinusIcon fontSize="12px"/>) : (<AddIcon fontSize="12px"/>)}
                </AccordionButton>
              </h2>
              <AccordionPanel paddingX={10} background="#F6F7FC">
                <Formik
                  innerRef={innerRef}
                  initialValues={initialValuesForm}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                  validateOnChange={true}
                >
                  {({
                      values,
                      isValid,
                      dirty,
                      handleChange,
                      setFieldValue,
                      setFieldTouched,
                    }) => {
                    onValidate(isValid && dirty);
                    return (
                      <Form>
                        <Box w="sm" mt="2">
                          <SelectFormik
                            placeholder={t('tumorRegistrationProcess.registrationSetup.placeholders.hospitalId')}
                            label={t('tumorRegistrationProcess.registrationSetup.labels.hospitalId')}
                            name="hospitalId"
                            mt="5"
                            isDisabled={isDisabled}
                            isRequired={true}
                            onChange={(e) => {
                              setFieldValue('analysisTypeId', '', true);
                              setFieldValue('contractCode', '', true);
                              setFieldValue('patientId', '', true);
                              setFieldTouched('analysisTypeId', false);
                              setFieldTouched('contractCode', false);
                              setFieldTouched('patientId', false);
                              onChangeHospital(Number(e.value));
                            }}
                            options={dataStore.hospitalOptions.map<Option>(
                              ({name, id}) => ({
                                label: name,
                                value: id.toString(),
                                key: name
                              })
                            )}
                          />
                        </Box>

                        {values.hospitalId !== undefined && !isSingleAnalysisType(dataStore) && (
                          <Box mt="5">
                            <RadioGroupChakraFormik
                              label={t('tumorRegistrationProcess.registrationSetup.labels.analysisTypeId')}
                              name="analysisTypeId"
                              isDisabled={isDisabled}
                              onChange={(event) => {
                                setFieldValue('contractCode', '', true);
                                setFieldValue('patientId', '', true);
                                setFieldTouched('contractCode', false);
                                setFieldTouched('patientId', false);
                                handleChange(event);
                                if (values.hospitalId !== undefined) {
                                  onChangeAnalysisType(Number(event.target.value), Number(values.hospitalId));
                                }
                              }}
                              options={dataStore.analysisTypeOptions.map<Option>(
                                ({name, id}) => ({
                                  label: name,
                                  value: id.toString()
                                })
                              )}
                              mb={4}
                              isRequired={true}
                            />
                          </Box>
                        )}

                        {(values.analysisTypeId.length > 0 || isSingleAnalysisType(dataStore)) && (
                          <Box mt="2">
                            <RadioGroupChakraFormik
                              label={t('tumorRegistrationProcess.registrationSetup.labels.contractCode')}
                              name="contractCode"
                              isDisabled={isDisabled}
                              options={dataStore.contractOptions.map<Option>(
                                ({name, contractCode}) => ({
                                  label: name,
                                  value: contractCode,
                                })
                              )}
                              onChange={(e) => {
                                if (values.hospitalId !== undefined) {
                                  values.studyIdPrefix = dataStore.getStudySampleIdStart(values.hospitalId, e.target.value, t);
                                  setFieldValue('patientId', "", true);
                                  setFieldTouched('patientId', false);
                                }
                                  dataStore.setContractType(e.target.value)
                                handleChange(e);
                              }}
                              isRequired={true}
                              mb={4}
                            />
                          </Box>
                        )}

                        {values.contractCode.length > 0 && (
                          dataStore.contractOptions.find(({contractCode}) => contractCode === values.contractCode)?.samplesAreDiagnostic
                            ?
                            <Box mt="5" w="sm">
                              <InputFormik
                                label={t('tumorRegistrationProcess.registrationSetup.labels.hospitalPatientId')}
                                placeholder={t('tumorRegistrationProcess.registrationSetup.placeholders.hospitalPatientId')}
                                isDisabled={isDisabled}
                                name="patientId"
                                isRequired={true}
                              />
                            </Box>
                            :
                            <Box mt="5" w="sm">
                              <PrefixedInputFormik
                                label={t('tumorRegistrationProcess.registrationSetup.labels.patientStudyNr')}
                                placeholder={t('tumorRegistrationProcess.registrationSetup.placeholders.patientStudyNr')}
                                isDisabled={isDisabled}
                                name="patientId"
                                isRequired={true}
                                prefix={values.studyIdPrefix}
                              />
                            </Box>
                        )}
                        {
                          <Box mt="5" w="sm">
                            <InputFormik mb="1"
                                         label={t("tumorRegistrationProcess.registrationSetup.labels.hospitalSampleLabel")}
                                         name="hospitalSampleLabel"
                                         isRequired={false}
                            />

                          </Box>
                        }
                        {(shouldDisplayReportLabelPreview(values)) &&
                          <Box mt="5" w="100%" fontSize="sm" color="secondary">
                                  <span>
                                      <i>{t("tumorRegistrationProcess.registrationSetup.labels.reportLabelPreview")} '{computeReportLabelPreview(values)}'</i>
                                  </span>
                          </Box>
                        }
                      </Form>
                    );
                  }}
                </Formik>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </>
    );
  }
);

