import {AddIcon, LockIcon, MinusIcon, UnlockIcon} from '@chakra-ui/icons';
import {AccordionButton, AccordionItem, AccordionPanel, Box,} from '@chakra-ui/react';
import {ConsentInformationConsentGivenEnum, ContractOptionTypeEnum} from '@hmf/portal-api-client';
import {Form, Formik} from 'formik';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RadioGroupChakraFormik, SelectFormik} from '../../Formik';
import {Option} from '../../Formik/SelectFormik';
import {StepConsentForm, useConsent} from '../hooks/useConsent';
import {StepTumorSampleRegistrationProps} from '../interfaces/Steps';

export const StepConsent: React.FC<
  StepTumorSampleRegistrationProps<StepConsentForm>
> = observer(
  ({
     dataStore,
     edit,
     onValidate,
     active,
     innerRef,
   }: StepTumorSampleRegistrationProps<StepConsentForm>) => {
    const [t] = useTranslation();
    const {initialValuesForm, onSubmit, consentVersionOptions, isDisabled, validationSchema} =
      useConsent(dataStore);

    const [wasHovered, setWasHovered] = useState<boolean>(false);

      let byContractType = ([key, value]) => {
          if (dataStore.tumorRegistrationProcess.contractType === ContractOptionTypeEnum.Study) {
              return value !== ConsentInformationConsentGivenEnum.UseOfBroadConsent;
          }
          return true;
      };

      let byHospital = ([key, value]) => {
          let hospital = dataStore.hospitalOptions.filter(ho => ho.id === dataStore.tumorRegistrationProcess.tumorRegistration?.hospitalId)[0];
          if (!hospital.isBroadConsentApplicable) {
              return value !== ConsentInformationConsentGivenEnum.UseOfBroadConsent;
          }
          return true;
      };

      return (
      <AccordionItem pb={4}>
        {({isExpanded}) => (
          <>
            <h2>
              <AccordionButton
                onMouseLeave={() => setWasHovered(false)}
                onMouseEnter={() => setWasHovered(true)}
              >
                <Box flex="1" marginLeft={!active ? '2' : '6'} textAlign="left">
                  {!isExpanded && !wasHovered ? (
                    <LockIcon fontSize="12px" marginRight="5"/>
                  ) : (
                    <UnlockIcon fontSize="12px" marginRight="5"/>
                  )}
                  {t('tumorRegistrationProcess.consent.title')}
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px"/>
                ) : (
                  <AddIcon fontSize="12px"/>
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel paddingX={10} background="#F6F7FC">
              <Formik
                innerRef={innerRef}
                validationSchema={validationSchema}
                initialValues={initialValuesForm}
                onSubmit={onSubmit}
              >
                {({values, isValid, dirty}) => {
                  onValidate(edit ? isValid : isValid && dirty);

                    return (
                    <Form>
                      <Box mt={5}>
                        <RadioGroupChakraFormik
                          label={t('tumorRegistrationProcess.consent.labels.consentGiven')}
                          name="consentGiven"
                          isDisabled={isDisabled}
                          options={Object.entries(
                            ConsentInformationConsentGivenEnum
                          ).filter(byContractType)
                              .filter(byHospital)
                              .map(([key, value]) => ({
                            label: t(`tumorRegistrationProcess.consent.options.${value.toLowerCase()}`),
                            value,
                          }))}
                          isRequired={true}
                        />
                      </Box>

                      {values.consentGiven === ConsentInformationConsentGivenEnum.Yes.valueOf() ||
                      values.consentGiven === ConsentInformationConsentGivenEnum.No.valueOf()
                        ? (
                          <Box w="sm" mt="2">
                            <SelectFormik
                              placeholder={t('tumorRegistrationProcess.consent.placeholders.consentVersion')}
                              label={t('tumorRegistrationProcess.consent.labels.consentVersion')}
                              name="consentVersion"
                              isDisabled={isDisabled}
                              mt="5"
                              options={consentVersionOptions.map<Option>(
                                (consentVersion) => {
                                  return {
                                    value: consentVersion.id.toString(),
                                    label: consentVersion.version,
                                  };
                                }
                              )}
                              isRequired={true}
                            />
                          </Box>
                        ) : (
                          <></>
                        )}
                    </Form>
                  );
                }}
              </Formik>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  }
);
