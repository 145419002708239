import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: 'en',
    load: 'languageOnly',
    debug: process.env.NODE_ENV !== 'production',
    backend: {
      queryStringParams: { v: `${process.env.REACT_APP_GIT_SHA}` }
    },
    parseMissingKeyHandler: (_key, defaultValue) => {
      if (!defaultValue) {
        console.log(`missing translation key [${_key}] without fallback, using '?' as fallback`);
        return '?';
      }
      return defaultValue;
    }
  });

export default i18n;
